import styled from 'styled-components';

const Styles = styled.div`

  padding: 1rem;
  background: -webkit-linear-gradient(left,  #ffffff, #ffffff);
  height: 100%;
  
  .MuiButtonGroup-groupedTextPrimary {
    border-color: #EEEDED;
  }
  table {
    width:100%;
    height: auto;
    border-radius: 10px;
    background: #fff;
    box-shadow: 0 10px 30px 0 rgba(50, 50, 50, 0.16);
    -webkit-box-shadow: 0 10px 30px 0 rgba(50, 50, 50, 0.16);
    -moz-box-shadow: 0 10px 30px 0 rgba(50, 50, 50, 0.16);
    position: relative; 
    // table-layout: fixed;
    thead {
      background: #47588f;
      text-align: center;
      padding: 30px;
    }
    
    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th {
        padding: 5px 10px;
        text-align: left;
        font-weight: 400;
        font-size: 14px;
        font-family: Calibri;
        color: #FFFFFF;
        text-transform: uppercase;
    }

    td {
        padding: 0px 6px;
        text-align: left;
        vertical-align:middle;
        font-weight: 300;
        font-size: 12px;
        color: #0E0D0D;
        border-top: solid 2px #2e4a72;

      :last-child {
        border-right: 0;
        color: #0E0D0D;
      }
      TextArea {
          color: white;
      }
    }
  }
`

export default Styles;