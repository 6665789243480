import { SyncOutlined } from '@ant-design/icons';
import { Tag, Modal, Tooltip, Input, message } from "antd";
import { useState } from 'react';
import BudgetRepo from "../helpers/budgetingRepo";
import './stylesTabsEdit.css';

const TagComponent = ({ subaccountID, categoryID, subaccounts, setSubaccounts, isEditable, setIsEditable, isDelete, setIsDelete, setDisableCollapse }) => {

    const { editSubAccount, deleteSubAccount } = BudgetRepo();

    const [loadingEdit, setLoadingEdit] = useState(false);

    //la posicion del input a editar
    const [editInputIndex, setEditInputIndex] = useState(-1);
    //el valor del input a editar
    const [editInputValue, setEditInputValue] = useState("");

    const handleEditInputChange = (e) => {
        setEditInputValue(e.target.value);
    };

    const editCode = () => {
        setLoadingEdit(true);
        const validateSubAccs = [...subaccounts]
        const previousValue = validateSubAccs[editInputIndex].name;
        const previousIndex = editInputIndex;
        for (const subAcc of validateSubAccs) {
            if (subAcc.name === editInputValue) {
                setLoadingEdit(false);
                setEditInputIndex(-1);
                setIsEditable(false);
                setDisableCollapse(false)
                return message.info("Ingresaste el mismo o uno ya existente: " + subAcc.name);
            }
        }
        validateSubAccs[editInputIndex].name = editInputValue;
        setSubaccounts(validateSubAccs);
        setIsEditable(false);
        setEditInputIndex(-1);

        editSubAccount({ id: validateSubAccs[editInputIndex].id, code: editInputValue })
            .then(({ data }) => {
                setLoadingEdit(false);
                setDisableCollapse(false)
                message.success("Se actualiazó al código: " + data.name);
            })
            .catch(err => {
                if (err.error) {
                    message.error(err.error, 5)
                } else {
                    message.error("Ocurrió un error al editar.", 3)
                }
                validateSubAccs[previousIndex].name = previousValue;
                setSubaccounts(validateSubAccs);
                setLoadingEdit(false);
                setDisableCollapse(false)
                console.log(err);
            })
    }

    const deleteCode = (removedCode) => {
        Modal.confirm({
            title: `ELIMINAR CÓDIGO`,
            // icon: <ExclamationCircleOutlined />,
            content: `Desea eliminar el código: ${removedCode.name}`,
            okText: "Aceptar",
            cancelText: "Cancelar",
            onOk() {
                const newSubAccs = subaccounts.filter((subacc) => subacc.name !== removedCode.name);
                setSubaccounts(newSubAccs);
                deleteSubAccount({ id: removedCode.id, code: removedCode.name })
                    .then(() => {
                        setIsDelete(false);
                        setDisableCollapse(false);
                        message.success(`Código "${removedCode.name}" eliminado con exito`);
                    })
                    .catch(err => {
                        setIsDelete(false);
                        setDisableCollapse(false);
                        console.log(err);
                    });
            },
            onCancel() {
                setIsDelete(false);
                setDisableCollapse(false);
            },
        });
    };

    return (
        <>
            {
                subaccounts.map((subaccount, i) => {
                    if (categoryID ? subaccount.id_category === categoryID : subaccount.id_account === subaccountID) {//Si es igual al codigo de cuenta
                        if (editInputIndex === i) {
                            return (
                                <Input
                                    key={subaccount.name}
                                    size="small"
                                    className="tag-input-budgeting"
                                    value={editInputValue}
                                    onChange={handleEditInputChange}
                                    onBlur={editCode}
                                    onPressEnter={editCode}
                                />
                            );
                        }
                        return (
                            <Tooltip key={i} placement="top" title={subaccount.summaries.reduce((ac, cv) => ac + cv.summary + "; ", "")}>
                                <Tag
                                    className={isEditable && !subaccount.summaries.length ? "edit-tag-budgeting tag-animation-editing-budgeting" : "edit-tag-budgeting"}
                                    color={subaccount.summaries.length ? "green" : "red"}
                                    icon={(loadingEdit && subaccount.name === editInputValue) ? <SyncOutlined spin /> : null}
                                    closable={!subaccount.summaries.length && isDelete ? true : false}
                                    onClose={(e) => {
                                        e.preventDefault()
                                        deleteCode(subaccount)
                                    }}
                                >
                                    <span
                                        onDoubleClick={(e) => {
                                            if (isEditable && !subaccount.summaries.length) {
                                                setEditInputIndex(i);
                                                setEditInputValue(subaccount.name);
                                                e.preventDefault();
                                            }
                                        }}
                                    >
                                        {subaccount.name}
                                    </span>
                                </Tag>
                            </Tooltip>
                        )
                    }
                })
            }
        </>
    )
}

export default TagComponent;