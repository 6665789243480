import { Layout } from "antd";
import { Outlet } from "react-router-dom";
import SiderMenu from "./components/SiderMenu";
import HeaderSection from "./components/Header";
import "./index.css";

const { Content, Footer } = Layout;

const AdminLayout = () => {
  return (
    <Layout>
      {/* HEADER */}
      <HeaderSection />
      <Layout hasSider>
        {/* MENU LATERAL */}
        <SiderMenu />
        {/* LAYOUT PRINCIPAL */}
        <Layout>
          {/* CONTENT */}
          <Content
            style={{
              minHeight: '83vh',
              margin: '24px 16px 0' 
            }}
          >
            <Outlet />
          </Content>
          {/* FOOTER */}
          <Footer style={{ textAlign: "center" }}>
            Armadillo Intranet © {new Date().getFullYear()} Armadillo
          </Footer>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default AdminLayout;
