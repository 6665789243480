import useToken from './useToken';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';

import axios from 'axios';

const url = '/api';
const axinstace = axios.create({
    baseURL: url,
    timeout: 120000,
    withCredentials: true
    // headers: {'X-Custom-Header': 'foobar'}
});

export default function DashRepo() {
    const { token, setToken } = useToken();
    const [cookies, setCookie, removeCookie] = useCookies(['token']);
    const navigate = useNavigate();
    const dFetch = ({ verb, path, data = undefined, params = undefined, headers }) => axinstace({
        method: verb,
        url: path,
        params,
        headers: { ...headers, token: token?.token},
        data: data
    }).catch(function (error) {
        if (error.response) {
            if (error.response.status === 401) {
                removeCookie('token');
                removeCookie('permissions');
                setToken(undefined);
                navigate('/');
            }
            throw (error.response.data);
        } else if (error.request) {
            throw ({ message: 'Error en Solicitud.', error: error.request })
        } else {
            // Something happened in setting up the request that triggered an Error
            throw ({ message: 'Error interno.', error: error.message })
        }
    });

    // fetch(`${url}/${path}`, {
    //     method: verb,
    //     headers: {
    //         'Content-Type': 'application/json',
    //         token
    //     },
    //     body: JSON.stringify(data)
    // }).then(async (response) => {
    //     if (!response.ok) {
    //        throw Error(response.statusText);
    //     }
    //     return response;
    // })
    return { dFetch }
}

