import React, { useEffect } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import useToken from './shared/useToken';
import { ToastCont } from './shared/Toast';
import { CookiesProvider } from "react-cookie";
import { version } from "../package.json";
import './App.css';

// Routes
// import Login from './components/login/LoginOld';
import Clients from './components/clients/Clients';
import Billing from './components/billing/Billing';
import Navbar from './components/navbar/Navbar';
import Vehicles from './components/vehicles/HomeVehicle';
import SyncLiderman from './components/liderman/SyncLiderman';
import Permissions from './components/permissions/Permissions';
import Costing from './components/costing/Costing';
import Quote from './components/costing/Quote';
import QuoteHistory from './components/costing/QuoteHistory';
import Main from './components/main/Main';
import OperationPerformance from './components/operationPerformance/Operation';
import Budgeting from './components/budgeting';
import ControlCheckpoints from './components/controlCheckpoints';
import ValidateCheckpoints from './components/validateCheckpoints';
import DashCheckpoints from './components/DashCheckpoints';
import ProtectedRoute from './guards/ProtectedRoute';
import ErrorPage403 from './components/error/ErrorPage403';
import ErrorPage404 from './components/error/ErrorPage404';
import Login from './components/login/Login';
import PrivateRoute from './guards/PrivateRoute';
import AdminLayout from './views/Admin';
import { SpinnerContextProvider } from './shared/contexts/useSpinner';


function App(): React.JSX.Element {
  const { token, setToken } = useToken();

  useEffect(() => {
    console.log(` %c| Portal Armadillo | ${version} `, 'background: #0d568cff; color: #fcab10ff; border-radius: 4px;')
  }, [token])

  return (
    <CookiesProvider>
      <SpinnerContextProvider>
        <ToastCont />
        <BrowserRouter>
          <Routes>
            {/* RUTAS PÚBLICAS (CUALQUIER USUARIO TIENE ACCESO) */}
            <Route path="/login" element={<Login setToken={setToken} />} />

            {/* RUTAS PRIVADAS (SE DEBE ESTAR LOGEADO PARA TENER ACCESO) */}
            <Route element={<PrivateRoute />}>
              {/* RUTAS PRINCIPALES */}
              <Route path="/" element={<AdminLayout />}>
                {/* HOME */}
                <Route path="" element={<Main />} />
                <Route path="/home" element={<Navigate to="/" />} />

                {/* RUTAS PROTEGIDAS (CON PERMISOS) */}
                <Route element={<ProtectedRoute />}>
                  <Route path="/clients" element={<Clients />} />
                  <Route path="/billing" element={<Billing />} />
                  <Route path="/vehicles" element={<Vehicles />} />
                  <Route path="/interfaceliderman" element={<SyncLiderman />} />
                  <Route path="/permissions" element={<Permissions />} />
                  <Route path="/costing/drivers" element={<Costing />} />
                  <Route path="/costing/newProforma" element={<Quote />} />
                  <Route path="/costing/historyQuote" element={<QuoteHistory />} />
                  <Route path="/costing/modifyQuote" element={<Quote />} />
                  <Route path="/operation/performance" element={<OperationPerformance />} />
                  <Route path="/budget" element={<Budgeting />} />
                  <Route path="/control/checkpoints" element={<ControlCheckpoints />} />
                  <Route path="/validate/checkpoints" element={<ValidateCheckpoints />} />
                  <Route path="/admin/checkpoints" element={<DashCheckpoints />} />
                </Route>

                {/* RUTAS DE ERROR */}
                <Route path="/error/403" element={<ErrorPage403 />} />
                <Route path="*" element={<ErrorPage404 />} />
              </Route>
            </Route>
          </Routes>
        </BrowserRouter>
      </SpinnerContextProvider>
    </CookiesProvider>
  );
}

export default App;
