import { Table, Tag } from "antd";
import { useEffect, useState } from "react";
import CardDetails from "../Cards/CardDetails";
import VerificationTable from "./VerificationTable";

const originalData = [];
for (let i = 0; i < 3; ++i) {
  originalData.push({
    key: i.toString(),
    opt: "123213123",
    vehiculo: "placa " + i,
    conductor: "Alan Arrieta",
    cliente: "Samsung",
    estado: "Activa",
    content: [
      {
        key: i.toString(),
        evento: "Recojo",
        almacen: "TALMA",
        pntArribo: ["12/12/2022 15:09:19"],
        ingAlmacen: ["12/12/2022 20:09:19"],
        aperPuertas: ["12/12/2022 20:09:19"],
        cierPuertas: ["12/12/2022 20:09:19"],
        salAlmacen: ["12/12/2022 20:09:19"],
        estado: "espera",
      },
      {
        key: (i + 1).toString(),
        evento: "Entrega",
        almacen: "RANSA",
        pntArribo: ["12/12/2022 20:09:19"],
        ingAlmacen: ["12/12/2022 20:09:19"],
        aperPuertas: ["12/12/2022 20:09:19"],
        cierPuertas: ["12/12/2022 20:09:19"],
        salAlmacen: ["12/12/2022 19:09:19"],
        estado: "aprobado",
      },
    ],
  });
}

const PrincipalVerificationTable = ({ reloadButton }) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    fetchData();
  }, [reloadButton]);

  const fetchData = () => {
    setLoading(true);
    setTimeout(() => {
      setData(originalData);
      setLoading(false);
    }, 1000);
  };

  const columns = [
    {
      title: "OPT",
      dataIndex: "opt",
      width: 140,
      render: (text) => <b>{text}</b>,
    },
    {
      title: "VEHICULO",
      dataIndex: "vehiculo",
    },
    {
      title: "CONDUCTOR",
      dataIndex: "conductor",
    },
    {
      title: "CLIENTE",
      dataIndex: "cliente",
    },
    {
      title: "ESTADO",
      dataIndex: "estado",
      render: (state) => {
        let color = state.length > 6 ? "#2db7f5" : "#87d068";
        if (state === "Cerrada") {
          color = "#D12905";
        }
        return <Tag color={color}>{state.toUpperCase()}</Tag>;
      }
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={data}
      bordered
      pagination={false}
      loading={loading}
      expandable={{
        expandedRowRender: (record) => (
          <CardDetails
            opt={record.opt}
            content={record.content}
            reloadButton={reloadButton}
          />
        ),
      }}
    />
  );
};

export default PrincipalVerificationTable;
