/* eslint-disable no-unused-vars */
import { Spin } from "antd";
import { useContext, useState, createContext } from "react";

interface ISpinnerContextParams {
  /**
   * Indica si el spinner está activo
   */
  isSpinning: boolean;
  /**
   * Mensaje a mostrar en el spinner
   * @default "Cargando..."
   */
  message?: string;
}
type SpinnerContextType = (params: ISpinnerContextParams) => void;
type ISpinnerContextProviderProps = {
  children: React.ReactNode;
};

//definicición del contexto
const SpinnerContext = createContext<SpinnerContextType>(() => {});
export default SpinnerContext;

/**
 * Proveedor del contexto SpinnerContext
 */
export const SpinnerContextProvider = ({
  children,
}: ISpinnerContextProviderProps) => {
  const [isSpinning, setIsLoading] = useState(false);
  const [message, setMessage] = useState<string | undefined>("Cargando...");

  //functions
  const showSpinner: SpinnerContextType = ({ isSpinning, message }) => {
    setIsLoading(isSpinning);
    setMessage(message);
  };

  return (
    <SpinnerContext.Provider value={showSpinner}>
      <Spin spinning={isSpinning} tip={message}>
        {children}
      </Spin>
    </SpinnerContext.Provider>
  );
};

//hook para consumir el contexto
export const useSpinner = (): SpinnerContextType => useContext(SpinnerContext);
