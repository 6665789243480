import {
  DownOutlined,
  UserOutlined,
  PoweroffOutlined,
} from "@ant-design/icons";
import { Avatar, MenuProps } from "antd";
import { Button, Dropdown, Space } from "antd";
import useToken from "../../../../shared/useToken";
import { useCookies } from "react-cookie";
import { ToastOk } from "../../../../shared/Toast";
import { useNavigate } from "react-router-dom";
import { useSpinner } from "../../../../shared/contexts/useSpinner";

const items: MenuProps["items"] = [
  {
    key: "1",
    danger: true,
    label: "Cerrar sesión",
    icon: <PoweroffOutlined />,
  },
];

/**
 * Dropdown botón de cuenta (usuario, cerrar sesión) 
 */
const AccountButton = () => {
  const [cookies, setCookie, removeCookie] = useCookies(["token", "name"]);
  const { setToken } = useToken();
  const navigate = useNavigate();
  const spinner = useSpinner();

  const logout = () => {
    spinner({ isSpinning: true, message: "Cerrando sesión" });
    setTimeout(() => {
      spinner({ isSpinning: false });
      setToken(undefined);
      removeCookie("token");
      removeCookie("name");
      ToastOk("Hasta pronto");
      navigate("/login");
    }, 1000);
  };

  return (
    <div style={{ float: "right" }}>
      <Dropdown menu={{ items, onClick: logout }}>
        <Button>
          <Space>
            <Avatar
              style={{
                backgroundColor: "#103160",
                marginTop: -13,
                marginBottom: -10,
              }}
              size={"small"}
              icon={<UserOutlined />}
            />
            {cookies.name || "Usuario"}
            <DownOutlined />
          </Space>
        </Button>
      </Dropdown>
    </div>
  );
};

export default AccountButton;
