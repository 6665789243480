import {
  UploadOutlined,
  DownloadOutlined,
  LoadingOutlined,
  FieldTimeOutlined,
  CarOutlined,
} from "@ant-design/icons";
import { useState, useEffect } from "react";
import { Typography, Badge, Descriptions, Steps } from "antd";
const { Title, Paragraph } = Typography;

const CardDetails = ({ opt, content, reloadButton }) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    fetchData();
  }, [reloadButton]);

  const fetchData = () => {
    setData(content);
  };

  return (
    <>
      {content.map((trip, i) => {
        return (
          <Badge.Ribbon
            key={i}
            text={trip.evento.toUpperCase()}
            color={
              trip.evento.toUpperCase() === "RECOJO" ? "#D12905" : "#6ED105"
            }
          >
            <Descriptions
              bordered
              column={1}
              style={{
                marginTop: 10,
              }}
              size="small"
              title=""
            >
              <Descriptions.Item
                label={
                  <>
                    <Title level={5}>{trip.almacen.toUpperCase()}</Title>
                    <br />
                    <Paragraph>
                      {"."}
                    </Paragraph>
                  </>
                }
              >
                <Steps
                  items={[
                    {
                      title: "Arribo",
                      subTitle: "12:00:00",
                      description: "Llegada a zona.",
                      status: "finish",
                      icon: <CarOutlined />,
                    },
                    {
                      title: "Ingreso",
                      subTitle: "12:00:00",
                      description: "Entrada a zona.",
                      status: "process",
                      icon: <LoadingOutlined />,
                    },
                    {
                      title: "Apertura",
                      subTitle: "",
                      description: "Puertas abiertas",
                      status: "wait",
                      icon: <FieldTimeOutlined />,
                    },
                    {
                      title: "Cierre",
                      subTitle: "",
                      description: "Puertas cerradas",
                      status: "wait",
                      icon: <FieldTimeOutlined />,
                    },
                    {
                      title: "Salida",
                      subTitle: "",
                      description: "Salida de zona.",
                      status: "wait",
                      icon: <FieldTimeOutlined />,
                    },
                  ]}
                />
              </Descriptions.Item>
            </Descriptions>
          </Badge.Ribbon>
        );
      })}
    </>
  );
};

export default CardDetails;
