import { HomeOutlined } from "@ant-design/icons";
import { Layout, Typography, Button, Result, Row } from "antd";
import { Link, useSearchParams } from "react-router-dom";
const { Text } = Typography;

/**
 * Página de error 403
 */
const ErrorPage403 = () => {
  const [searchParams] = useSearchParams();

  return (
    <Layout className="layout">
      <Layout.Content style={{ padding: "0 50px" }}>
        <Row justify="center" align="middle" style={{ height: "80vh" }}>
          <Result
            status="403"
            title="🚫 No tienes permisos para acceder a esta página"
            subTitle={
              <Text type="secondary">
                Error al acceder a la ruta{" "}
                <code>"{searchParams.get("pathName")}"</code> <br />
                Código de error: 403 (Forbidden)
              </Text>
            }
            extra={
              <Link to="/">
                <Button type="primary" icon={<HomeOutlined />}>
                  Volver al inicio
                </Button>
              </Link>
            }
          />
        </Row>
      </Layout.Content>
    </Layout>
  );
};

export default ErrorPage403;
