import { Typography, Row } from 'antd';
const { Text, Link } = Typography;

export default function Copyright() {
  return (
    <Row style={{marginTop: "15px"}} justify="center">
      <Text align="center" style={{color: "#fff"}}>
        {'© '}
        <Link color="inherit" href="https://dash.etarmadillo.com/">
          Intranet Armadillo
        </Link>{' '}
        {new Date().getFullYear()}
      </Text>
    </Row>
  );
}
