import { Outlet, Navigate } from 'react-router-dom'
import useToken from '../shared/useToken';

/**
 * Componente que valida si el usuario está logeado (tiene un token) para acceder a las rutas privadas
 */
const PrivateRoute = () => {
  const { token } = useToken();

  //si el usuario tiene un token, se muestra el contenido de la ruta
  //de lo contrario se redirige a la página de login
  return(
    token ? <Outlet /> : <Navigate to="/login" />
  );
}

export default PrivateRoute;
