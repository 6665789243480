import { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Table, Tag, Space, Button, Input, Typography, Spin } from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined, LoadingOutlined } from '@ant-design/icons';
import CostingRepo from './CostingRepo';
import QuoteDetails from './QuoteDetails';
const antIcon = (
    <LoadingOutlined
        style={{
            fontSize: 24,
        }}
        spin
    />
);


export default function AcceptedQuotes({ update }) {

    const [dataQuotes, setDataQuotes] = useState([]);
    const { allQuotes } = CostingRepo();
    const searchInput = useRef(null);
    const [searchText, setSearchText] = useState();
    const [searchedColumn, setSearchedColumn] = useState();
    const [recordDetailsQuote, setRecordDetailsQuote] = useState({});
    const [modalDetails, setModalDetails] = useState(false);
    const [loadingCost, setLoadingCost] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        allQuotes().then(data => {
            let proformas = data.data;
            for (let i = 0; i < proformas.length; i++) {
                let totalPrice = 0
                for (let j = 0; j < proformas[i].costing_routes_data_final_quotes.length; j++) {
                    totalPrice += parseInt(proformas[i].costing_routes_data_final_quotes[j].finalPrice);
                }
                proformas[i].totalPrice = totalPrice;
                proformas[i].key = proformas[i].id;
                proformas[i].margen = (((totalPrice - proformas[i].totalCost) / totalPrice) * 100).toFixed(2) + " %";
                proformas[i].newMargen = (((totalPrice - proformas[i].currentCost) / totalPrice) * 100).toFixed(2) + " %";
            }
            setDataQuotes(proformas);
            setLoadingCost(false);
        }).catch(err => {
            console.warn(err)
            setLoadingCost(false)
            setDataQuotes([])
        })
    }, [update])

    const getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={searchInput}
                    //   ref={node => {
                    //     searchInput(node);
                    //   }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ marginBottom: 2, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Buscar
                    </Button>
                    <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({ closeDropdown: false });
                            setSearchText(selectedKeys[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filtrar
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined, padding: "10px", fontSize: "15px" }} />,
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => searchInput.current.select(), 100);
            }
        },
        render: text =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = clearFilters => {
        clearFilters();
        setSearchText('');
    };

    const detailsQuote = (record) => {
        setModalDetails(true);
        setRecordDetailsQuote(record);
    }

    const ModifyQuote = (record) => {
        navigate("/costing/modifyQuote", { state: { ...record, edit: true } });
    }

    const columns = [
        {
            title: '#',
            dataIndex: 'key',
            key: 'key',
        },
        {
            title: 'Father',
            dataIndex: 'idFather',
            key: 'idFather',
        },
        {
            title: 'Vers',
            dataIndex: 'version',
            key: 'version',
        },
        {
            title: 'Cliente',
            dataIndex: 'clientName',
            key: 'clientName',
            ...getColumnSearchProps('clientName'),
        },
        {
            title: 'Ruc',
            dataIndex: 'identificator',
            key: 'identificator',
            ...getColumnSearchProps('identificator'),
        },
        {
            title: 'Fecha',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (text, record) => {
                const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
                const d = new Date(record.createdAt);
                const date = d.toLocaleDateString(undefined, options);
                return (
                    <span>
                        {date}
                    </span>
                )
            },
        },
        {
            title: 'Costo incial',
            dataIndex: 'totalCost',
            key: 'totalCost',
        },
        {
            title: 'Margen',
            dataIndex: 'margen',
            key: 'margen',
        },
        {
            title: 'Precio total',
            dataIndex: 'totalPrice',
            key: 'tiralPrice',
        },
        {
            title: 'Costo actual',
            dataIndex: 'currentCost',
            key: 'currentCost',
        },
        {
            title: 'Indicador',
            key: 'tag',
            dataIndex: 'tag',
            render: (text, record) => {
                let msg = "";
                let color = "";
                if (record.tag === 0) {
                    msg = "El margen se mantuvo";
                    color = "#14AEEC"
                } else if (record.tag > 0) {
                    msg = "El margen disminuyó";
                    color = "#E74F17"
                } else {
                    msg = "El margen aumentó";
                    color = "#43E513 "
                }
                return (
                    <span>
                        <Tag color={color}>{msg}</Tag>
                    </span>
                )
            },
        },
        {
            title: 'Nuevo Margen',
            dataIndex: 'newMargen',
            key: 'newMargen',
        },
        {
            title: 'Action',
            key: 'action',
            render: (text, record) => {
                return (
                    <span>
                        <Typography.Link
                            onClick={() => detailsQuote(record)}
                            style={{
                                marginRight: 8,
                            }}
                        >
                            Detalles
                        </Typography.Link>
                        <Typography.Link
                            onClick={() => ModifyQuote(record)}
                            style={{
                                marginRight: 8,
                            }}
                        >
                            Modificar
                        </Typography.Link>
                    </span>
                )
            },
        },
    ];

    return (
        <div className='tableQuoteHistory'>
            {loadingCost ?
                <div style={{ height: "200px", position: "relative", minWidth: "100%", background: "white" }}>
                    <div style={{ position: "relative", top: "50%", left: "30%", color: "black" }}><Spin indicator={antIcon} /> Calculando costos </div>
                </div> :
                <Table style={{ width: '100%', overflow: 'scroll' }} columns={columns} dataSource={dataQuotes} />
            }

            <QuoteDetails modalDetails={modalDetails} setModalDetails={setModalDetails} recordDetailsQuote={recordDetailsQuote} />
        </div>
    );
}