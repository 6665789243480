import { Tabs, Card } from 'antd';
import { useState } from 'react';
import BudgetTable from './budget/BudgetTable';
import ImportData from './modalImport/importData';
import PLTable from './pl/PLTable';
import { useCookies } from 'react-cookie';


export default function App() {

  const [cookies, setCookie, removeCookie] = useCookies(['token', 'name']);
  const fecha = new Date()
  const currentYear = fecha.getFullYear();
  const [year, setYear] = useState(currentYear);

  return (
    <Card title="Total Costos y Gastos">
      <div>
        <Tabs defaultActiveKey="1" tabBarExtraContent={cookies.name === "Ricardo" || cookies.name === "Area TI" ? <ImportData /> : null}>
          <Tabs.TabPane tab="BUDGET" key="1">
            <BudgetTable year={year} setYear={setYear} blockYears={false} type={'budget'} />
          </Tabs.TabPane>
          <Tabs.TabPane tab="MTD" key="2">
            <BudgetTable year={year} setYear={setYear} blockYears={false} type={'real'} />
          </Tabs.TabPane>
          <Tabs.TabPane tab="PY" key="3">
            <BudgetTable year={year-1} blockYears={true} type={'real'} />
          </Tabs.TabPane>
          <Tabs.TabPane tab="P&L" key="4">
            <PLTable year={year} />
          </Tabs.TabPane>
        </Tabs>
      </div>
    </Card>
  );

}