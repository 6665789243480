import {
  Collapse,
  Typography,
  Form,
  Input,
  Button,
  Cascader,
  message,
} from "antd";
import React, { useEffect, useState } from "react";
import ExtraOfCollapse from "./ExtraOfCollapse";
import BudgetRepo from "../helpers/budgetingRepo";
import TagComponent from './TagComponent';

const { Panel } = Collapse;

const optionsCascader = [
  {
    value: 1,
    label: "Gross Revenue",
  },
  {
    value: 2,
    label: "Costo de Venta",
    children: [
      {
        value: 1,
        label: "OPERADORES",
      },
      {
        value: 2,
        label: "CO-OPERADORES",
      },
      {
        value: 3,
        label: "CONTROLADORES",
      },
      {
        value: 4,
        label: "SIPERVISION",
      },
      {
        value: 5,
        label: "SEGUROS",
      },
      {
        value: 6,
        label: "COSTO DE UNIDADES",
      },
      {
        value: 7,
        label: "LEASING",
      },
      {
        value: 8,
        label: "OTRAS RELACIONADAS A OPERACION",
      },
    ],
  },
  {
    value: 3,
    label: "Gastos Administrativos y de Venta",
  },
  {
    value: 4,
    label: "Depreciación",
  },
  {
    value: 5,
    label: "Perdida Cambiaria",
  },
  {
    value: 6,
    label: "Gastos Financieros",
  },
  {
    value: 7,
    label: "Impuestos",
  },
];

const initialValuesExtra = [
  true, [true, true, true, true, true, true, true, true], true, true, true, true, true
]

const ContentCodeDrawer = () => {
  const { getSubAccounts, createSubAccount } = BudgetRepo();
  const [form] = Form.useForm();

  const [loadingButton, setLoadingButton] = useState(false)
  const [subaccounts, setSubaccounts] = useState([]);
  const [openPanels, setOpenPanels] = useState([]);
  const [openNestedPanels, setOpenNestedPanels] = useState([]);
  const [disableCollapse, setDisableCollapse] = useState(false);

  const [disableEditOrDelete, setDisableEditOrDelete] = useState(initialValuesExtra);
  const [isEditable, setIsEditable] = useState(false);
  const [isDelete, setIsDelete] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    getSubAccounts()
      .then((data) => {
        setSubaccounts(data.data)
        verifyEditOrDelete(data.data)
      });
  }

  //Si se agregan mas categorias o subcategorias debe agrear aqui tambien
  const verifyEditOrDelete = (data) => {
    // debugger
    const newArray = [...initialValuesExtra];
    data.map(subaccount => {
      if (!subaccount.summaries.length) {
        if (subaccount.id_category) {
          if (subaccount.id_category === 1) {
            newArray[1][0] = false
            return
          } else if (subaccount.id_category === 2) {
            newArray[1][1] = false
            return
          } else if (subaccount.id_category === 3) {
            newArray[1][2] = false
            return
          } else if (subaccount.id_category === 4) {
            newArray[1][3] = false
            return
          } else if (subaccount.id_category === 5) {
            newArray[1][4] = false
            return
          } else if (subaccount.id_category === 6) {
            newArray[1][5] = false
            return
          } else if (subaccount.id_category === 7) {
            newArray[1][6] = false
            return
          } else if (subaccount.id_category === 8) {
            newArray[1][7] = false
            return
          }
        } else {
          if (subaccount.id_account === 1) {
            newArray[0] = false
            return
          } else if (subaccount.id_account === 3) {
            newArray[2] = false
            return
          } else if (subaccount.id_account === 4) {
            newArray[3] = false
            return
          } else if (subaccount.id_account === 5) {
            newArray[4] = false
            return
          } else if (subaccount.id_account === 6) {
            newArray[5] = false
            return
          } else if (subaccount.id_account === 7) {
            newArray[6] = false
            return
          }
        }
      }
    });
    setDisableEditOrDelete(newArray);
  }

  const handleCreateCode = (values) => {
    setLoadingButton(true)
    let obj;
    if (values.seleccion[0] === 2) {
      obj = {
        name: values.codigo,
        id_account: null,
        id_category: values.seleccion[1]
      }
    } else {
      obj = {
        name: values.codigo,
        id_account: values.seleccion[0],
        id_category: null
      }
    }
    createSubAccount(obj)
      .then((data) => {
        setSubaccounts(data.data)
        message.success("Código de cuenta: " + values.codigo + " creado correctamente.", 5);
        form.resetFields();
        setLoadingButton(false);
        setTimeout(() => {
          if (values.seleccion[0] === 2) {
            setOpenPanels([values.seleccion[0]]);
            setOpenNestedPanels([values.seleccion[1]])
          } else {
            setOpenPanels(values.seleccion)
          }
        }, 1000)
      })
      .catch(err => {
        if (err.error) {
          message.error(err.error, 5)
        }else{
          message.error(err.message)
        }
        setLoadingButton(false);
        console.log(err);
      })
  };

  const openCollapseToEditOrDelete = (itemIndex, idCategory, idSubCategory) => {
    setDisableCollapse(true)
    if (idCategory === 2) {
      setOpenNestedPanels([idSubCategory])
    } else {
      setOpenPanels(idCategory)
    }
    if (itemIndex === "1") {//EDITAR
      setIsEditable(true);
    } else if (itemIndex === "2") {//ELIMINAR
      setIsDelete(true)
    }
  }

  return (
    <>
      <Typography.Title level={5}>Agregar nuevo código</Typography.Title>
      <Form form={form} onFinish={handleCreateCode} layout="horizontal">
        <Form.Item
          label="Código"
          name="codigo"
          rules={[
            {
              required: true,
              message: "Completar el campo.",
            },
          ]}
        >
          <Input placeholder="Ingrese nombre del código" />
        </Form.Item>
        <Form.Item
          label="Selección"
          name="seleccion"
          rules={[
            {
              required: true,
              message: "Seleccione una opción",
            },
          ]}
        >
          <Cascader
            options={optionsCascader}
            placeholder="Seleccionar opción"
          />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loadingButton} disabled={disableCollapse} >
            Crear
          </Button>
        </Form.Item>
      </Form>
      <Typography.Title level={5}>
        Listar, editar o eliminar códigos por categoría
      </Typography.Title>
      <Collapse activeKey={openPanels} onChange={setOpenPanels} collapsible={disableCollapse ? "disabled" : "header"} >
        <Panel header="Gross Revenue" key="1" extra={<ExtraOfCollapse idCategory={1} openCollapse={openCollapseToEditOrDelete} disable={disableEditOrDelete[0]} />}>
          <div>
            <TagComponent
              subaccountID={1}
              subaccounts={subaccounts}
              setSubaccounts={setSubaccounts}
              isEditable={isEditable}
              setIsEditable={setIsEditable}
              isDelete={isDelete}
              setIsDelete={setIsDelete}
              setDisableCollapse={setDisableCollapse}
            />
          </div>
        </Panel>
        <Panel header="Costo de Venta" key="2">
          <Collapse activeKey={openNestedPanels} onChange={setOpenNestedPanels} collapsible={disableCollapse ? "disabled" : "header"}>
            <Panel header="OPERADORES" key="1" extra={<ExtraOfCollapse idCategory={2} idSubCategory={1} openCollapse={openCollapseToEditOrDelete} disable={disableEditOrDelete[1][0]} />}>
              <div>
                <TagComponent
                  categoryID={1}
                  subaccounts={subaccounts}
                  setSubaccounts={setSubaccounts}
                  isEditable={isEditable}
                  setIsEditable={setIsEditable}
                  isDelete={isDelete}
                  setIsDelete={setIsDelete}
                  setDisableCollapse={setDisableCollapse}
                />
              </div>
            </Panel>
            <Panel header="CO-OPERADORES" key="2" extra={<ExtraOfCollapse idCategory={2} idSubCategory={2} openCollapse={openCollapseToEditOrDelete} disable={disableEditOrDelete[1][1]} />}>
              <div>
                <TagComponent
                  categoryID={2}
                  subaccounts={subaccounts}
                  setSubaccounts={setSubaccounts}
                  isEditable={isEditable}
                  setIsEditable={setIsEditable}
                  isDelete={isDelete}
                  setIsDelete={setIsDelete}
                  setDisableCollapse={setDisableCollapse}
                />
              </div>
            </Panel>
            <Panel header="CONTROLADORES" key="3" extra={<ExtraOfCollapse idCategory={2} idSubCategory={3} openCollapse={openCollapseToEditOrDelete} disable={disableEditOrDelete[1][2]} />}>
              <div>
                <TagComponent
                  categoryID={3}
                  subaccounts={subaccounts}
                  setSubaccounts={setSubaccounts}
                  isEditable={isEditable}
                  setIsEditable={setIsEditable}
                  isDelete={isDelete}
                  setIsDelete={setIsDelete}
                  setDisableCollapse={setDisableCollapse}
                />
              </div>
            </Panel>
            <Panel header="SUPERVISION" key="4" extra={<ExtraOfCollapse idCategory={2} idSubCategory={4} openCollapse={openCollapseToEditOrDelete} disable={disableEditOrDelete[1][3]} />}>
              <div>
                <TagComponent
                  categoryID={4}
                  subaccounts={subaccounts}
                  setSubaccounts={setSubaccounts}
                  isEditable={isEditable}
                  setIsEditable={setIsEditable}
                  isDelete={isDelete}
                  setIsDelete={setIsDelete}
                  setDisableCollapse={setDisableCollapse}
                />
              </div>
            </Panel>
            <Panel header="SEGUROS" key="5" extra={<ExtraOfCollapse idCategory={2} idSubCategory={5} openCollapse={openCollapseToEditOrDelete} disable={disableEditOrDelete[1][4]} />}>
              <div>
                <TagComponent
                  categoryID={5}
                  subaccounts={subaccounts}
                  setSubaccounts={setSubaccounts}
                  isEditable={isEditable}
                  setIsEditable={setIsEditable}
                  isDelete={isDelete}
                  setIsDelete={setIsDelete}
                  setDisableCollapse={setDisableCollapse}
                />
              </div>
            </Panel>
            <Panel header="COSTO UNIDADES" key="6" extra={<ExtraOfCollapse idCategory={2} idSubCategory={6} openCollapse={openCollapseToEditOrDelete} disable={disableEditOrDelete[1][5]} />}>
              <div>
                <TagComponent
                  categoryID={6}
                  subaccounts={subaccounts}
                  setSubaccounts={setSubaccounts}
                  isEditable={isEditable}
                  setIsEditable={setIsEditable}
                  isDelete={isDelete}
                  setIsDelete={setIsDelete}
                  setDisableCollapse={setDisableCollapse}
                />
              </div>
            </Panel>
            <Panel header="LEASING" key="7" extra={<ExtraOfCollapse idCategory={2} idSubCategory={7} openCollapse={openCollapseToEditOrDelete} disable={disableEditOrDelete[1][6]} />}>
              <div>
                <TagComponent
                  categoryID={7}
                  subaccounts={subaccounts}
                  setSubaccounts={setSubaccounts}
                  isEditable={isEditable}
                  setIsEditable={setIsEditable}
                  isDelete={isDelete}
                  setIsDelete={setIsDelete}
                  setDisableCollapse={setDisableCollapse}
                />
              </div>
            </Panel>
            <Panel header="OTROS RELACIONADOS OPERACION" key="8" extra={<ExtraOfCollapse idCategory={2} idSubCategory={8} openCollapse={openCollapseToEditOrDelete} disable={disableEditOrDelete[1][7]} />}>
              <div>
                <TagComponent
                  categoryID={8}
                  subaccounts={subaccounts}
                  setSubaccounts={setSubaccounts}
                  isEditable={isEditable}
                  setIsEditable={setIsEditable}
                  isDelete={isDelete}
                  setIsDelete={setIsDelete}
                  setDisableCollapse={setDisableCollapse}
                />
              </div>
            </Panel>
          </Collapse>
        </Panel>
        <Panel header="Gastos Administrativos y de Ventas" key="3" extra={<ExtraOfCollapse idCategory={3} openCollapse={openCollapseToEditOrDelete} disable={disableEditOrDelete[2]} />}>
          <div>
            <TagComponent
              subaccountID={3}
              subaccounts={subaccounts}
              setSubaccounts={setSubaccounts}
              isEditable={isEditable}
              setIsEditable={setIsEditable}
              isDelete={isDelete}
              setIsDelete={setIsDelete}
              setDisableCollapse={setDisableCollapse}
              disableEditOrDelete={disableEditOrDelete}
              setDisableEditOrDelete={setDisableEditOrDelete}
            />
          </div>
        </Panel>
        <Panel header="Depreciación" key="4" extra={<ExtraOfCollapse idCategory={4} openCollapse={openCollapseToEditOrDelete} disable={disableEditOrDelete[3]} />}>
          <div>
            <TagComponent
              subaccountID={4}
              subaccounts={subaccounts}
              setSubaccounts={setSubaccounts}
              isEditable={isEditable}
              setIsEditable={setIsEditable}
              isDelete={isDelete}
              setIsDelete={setIsDelete}
              setDisableCollapse={setDisableCollapse}
              disableEditOrDelete={disableEditOrDelete}
              setDisableEditOrDelete={setDisableEditOrDelete}
            />
          </div>
        </Panel>
        <Panel header="Perdida Cambiaria" key="5" extra={<ExtraOfCollapse idCategory={5} openCollapse={openCollapseToEditOrDelete} disable={disableEditOrDelete[4]} />}>
          <div>
            <TagComponent
              subaccountID={5}
              subaccounts={subaccounts}
              setSubaccounts={setSubaccounts}
              isEditable={isEditable}
              setIsEditable={setIsEditable}
              isDelete={isDelete}
              setIsDelete={setIsDelete}
              setDisableCollapse={setDisableCollapse}
              disableEditOrDelete={disableEditOrDelete}
              setDisableEditOrDelete={setDisableEditOrDelete}
            />
          </div>
        </Panel>
        <Panel header="Gastos Financieros" key="6" extra={<ExtraOfCollapse idCategory={6} openCollapse={openCollapseToEditOrDelete} disable={disableEditOrDelete[5]} />}>
          <div>
            <TagComponent
              subaccountID={6}
              subaccounts={subaccounts}
              setSubaccounts={setSubaccounts}
              isEditable={isEditable}
              setIsEditable={setIsEditable}
              isDelete={isDelete}
              setIsDelete={setIsDelete}
              setDisableCollapse={setDisableCollapse}
              disableEditOrDelete={disableEditOrDelete}
              setDisableEditOrDelete={setDisableEditOrDelete}
            />
          </div>
        </Panel>
        <Panel header="Impuestos" key="7" extra={<ExtraOfCollapse idCategory={7} openCollapse={openCollapseToEditOrDelete} disable={disableEditOrDelete[6]} />}>
          <div>
            <TagComponent
              subaccountID={7}
              subaccounts={subaccounts}
              setSubaccounts={setSubaccounts}
              isEditable={isEditable}
              setIsEditable={setIsEditable}
              isDelete={isDelete}
              setIsDelete={setIsDelete}
              setDisableCollapse={setDisableCollapse}
              disableEditOrDelete={disableEditOrDelete}
              setDisableEditOrDelete={setDisableEditOrDelete}
            />
          </div>
        </Panel>
      </Collapse>
    </>
  );
};

export default ContentCodeDrawer;
