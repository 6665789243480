import { Layout, Button, Menu, MenuProps } from "antd";
import Icon, {
  HomeOutlined,
  LineChartOutlined,
  AuditOutlined,
  BankOutlined,
  DollarOutlined,
  TeamOutlined,
  CrownOutlined,
  TruckOutlined
} from "@ant-design/icons";
// import { Icon } from '@ant-design/compatible';
import { useNavigate } from "react-router-dom";
import useToken from "../../../../shared/useToken";
import { useState } from "react";

const { Sider } = Layout;
const test = "BankOutlined"
/**
 * Menu lateral
 */
const SiderMenu: React.FC = () => {
  const { token } = useToken();
  const navigate = useNavigate();

  //const Icon = ({ type, ...rest }) => {
    //const icons = require(`@ant-design/icons`);
    //const Component = icons[type];
    //return <Component {...rest} />;
  //};

  //renderiza los items del menú
  const renderMenuItems = (menus: any[]): MenuProps["items"] => {
    return menus.map((menu) => {
      return {
        key: `menu-${menu.id}`,
        icon: menu.id === 0
          ? <HomeOutlined />
          : menu.description_span === "Administración"
            ? <BankOutlined />
            : menu.description_span === "Operaciones"
              ? <TruckOutlined />
              : menu.description_span === "Finanzas"
                ? <LineChartOutlined />
                : menu.description_span === "Clientes"
                  ? <TeamOutlined />
                  : menu.description_span === "RRHH"
                    ? <AuditOutlined />
                    : menu.description_span === "Cotizaciones"
                      ? <DollarOutlined />
                      : menu.description_span === "Admin"
                        ? <CrownOutlined />
                        : null,
        label: menu.description_span,
        children:
          //si el menú tiene submenú, los renderiza
          menu.secondMenu.length > 0
            ? menu.secondMenu.map((item: any) => {
              return {
                key: `seconMenu-${item.id}`,
                label: item.description_span,
                onClick: () => {
                  const targetUrl =
                    item.controller.substring(0, 1) === "/" ||
                      item.controller.substring(0, 4) === "http"
                      ? item.controller
                      : `https://mundo.etarmadillo.com/${item.controller}`;

                  //si la ruta es un enlace externo, abre una nueva pestaña
                  if (item.controller.substring(0, 4) === "http") {
                    window.open(targetUrl, "_blank");
                  } else {
                    //navegar a la url
                    navigate(targetUrl);
                  }
                },
              };
            })
            : null,
        onClick:
          //si el menú tiene submenús, esto es undefined para que no se ejecute
          menu.secondMenu.length > 0
            ? undefined
            : () => {
              if (menu.controller.substring(0, 1) === "/") {
                navigate(menu.controller);
              } else if (menu.controller.substring(0, 4) === "http") {
                // window.location.href = menu.controller;
                window.open(menu.controller, "_blank");
              } else {
                // window.location.href = menu.controller;
                window.open(menu.controller, "_blank");
              }
            },
      };
    });
  };

  return (
    <Sider width={250} collapsible collapsedWidth="60" style={{background: "#2e4a72"}}>
    <div>
        <Menu
          mode="inline"
          theme="dark"
          defaultSelectedKeys={["1"]}
          style={{ height: "100%", borderRight: 0 }}
          items={renderMenuItems(token.permissions)}
        />
    </div>
    </Sider>
  );
};

export default SiderMenu;
