import logo from "../../../../assets/logo_appbar.png";

/**
 * Logo de la aplicación (vista admin)
 */
const Logo = () => {
  return (
    <div className="logo-admin">
      <a href="https://mundo.etarmadillo.com">
        <img
          src={logo}
          alt="armadillo logo"
          style={{
            width: "100%",
            height: "auto",
          }}
        />
      </a>
    </div>
  );
};

export default Logo;