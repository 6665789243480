import { useEffect, useState } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import useToken from "../shared/useToken";
import DashRepo from "../shared/DashRepo";
import { Spin } from "antd";

/**
 * Componente que verifica si el usuario tiene permisos para acceder a una ruta
 */
const ProtectedRoute = () => {
  const { token } = useToken();
  const { dFetch } = DashRepo();
  const [hasPermission, setHasPermission] = useState<boolean | null>(null);
  const location = useLocation();

  //verifica los permisos
  useEffect(() => {
    //obtiene los permisos del usuario desde el backend
    const getPermissions = async () => {
      try {
        await dFetch({
          verb: "POST",
          path: `login/validateMenu/${token.id}`,
          headers: {
            "Content-Type": "application/json",
          },
          //@ts-expect-error
          data: {
            menuPath: location.pathname,
          },
        });
        setHasPermission(true);
      } catch (error) {
        console.log("error ->", error);
        setHasPermission(false);
      }
    };

    getPermissions();
  }, []);

  if (hasPermission === null) {
    return (
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          color: "black",
        }}
      >
        <Spin /> Verificando permisos
      </div>
    );
  }

  //si el usuario tiene permisos, se muestra el contenido de la ruta
  //de lo contrario se redirige a la página de error 403
  return (
    hasPermission ? <Outlet /> : <Navigate to={`/error/403?pathName=${location.pathname}`} />
  );
};

export default ProtectedRoute;
