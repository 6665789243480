import { SettingOutlined } from "@ant-design/icons";
import { Dropdown } from 'antd';
import { useState } from 'react';

const ExtraOfCollapse = ({ idCategory, idSubCategory, openCollapse, disable }) => {

    const items = [
        {
            label: 'Editar un código',
            key: '1',
            disabled: disable ? true : false
        },
        {
            label: 'Eliminar un código',
            key: '2',
            disabled: disable ? true : false,
        },
    ]

    const [openDropdown, setOpenDropdown] = useState(false);

    const handleOpenChange = (flag) => {
        setOpenDropdown(flag);
    };

    return (
        <Dropdown
            menu={{
                items,
                onClick: (e) => {
                    setOpenDropdown(false);
                    openCollapse(e.key, idCategory, idSubCategory);
                },
            }}
            onOpenChange={handleOpenChange}
            open={openDropdown}
            trigger={['click']}
            placement="bottomRight"
            // disabled={true}
        >
            <SettingOutlined
                onClick={(event) => {
                    // If you don't want click extra trigger collapse, you can prevent this:
                    event.stopPropagation();
                    event.preventDefault();
                }}
            />
        </Dropdown>
    )
}

export default ExtraOfCollapse;