import React, { useState } from 'react';
import md5 from 'md5';
import { Button, Form, Layout, Image, Checkbox, Typography, Spin } from "antd";
import { UserOutlined, LockOutlined } from '@ant-design/icons'
import LoginRepo from './LoginRepo';
import { ToastOk, ToastErr, ToastCont } from '../../shared/Toast';
import './Login.css';
import Copyright from "../../shared/Copyright";
import logo from '../../assets/LogoArmadilloCirculo.png';
import { useNavigate } from 'react-router-dom';

const { Title } = Typography

const Login = ({ setToken }) => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const { getLogin, getVersion } = LoginRepo();

    const onFinish = async (values) => {
        setLoading(true);
        try {
            const response = await getLogin({
                username: values.username,
                password: md5(values.password)
            });
            setToken(response.data);
            ToastOk(response.message);
            setLoading(false);
            navigate('/');
        } catch (error) {
            setLoading(false);
            console.error(error.error);
            if(error.message === undefined){
                return ToastErr("Error al encontrar el servidor (500)")
            }
            ToastErr(error.message);
        }
    };

    return (
        <Layout className="background-login">
            <ToastCont />
            <div className="wrapper-login">
                <div className="logo-login">
                    <Image preview={false} src={logo} alt='Logo Empresa de Transportes Armadillo, S.A.C.' />
                </div>
                <Form initialValues={{ remember: true }} onFinish={onFinish} autoComplete="true">
                    <Title className="title-login">Iniciar Sesión</Title>
                    <Form.Item name="username" className="for-item-login" rules={[{ required: true, message: 'Ingresa tu usuario!' }]}>
                        <div className="input-box-login">
                            <input type="text" placeholder="Usuario" required />
                            <UserOutlined className="icon-login" />
                        </div>
                    </Form.Item>
                    <Form.Item name="password" className="for-item-login" rules={[{ required: true, message: 'Ingresa tu contraseña!' }]}>
                        <div className="input-box-login">
                            <input type="password" placeholder="Contraseña" required />
                            <LockOutlined className="icon-login" />
                        </div>
                    </Form.Item>
                    <div className="remember-forgot-login">
                        <Form.Item name="remember" valuePropName="checked" className="for-item-login">
                            <Checkbox className="checkbox-login">Mantener la sesión</Checkbox>
                        </Form.Item>
                        <Form.Item className="for-item-login">
                            <a href="#">Restablecer contraseña</a>
                        </Form.Item>
                    </div>
                    <Form.Item className="for-item-login">
                        <Button type="primary" htmlType="submit">
                            {loading && <Spin size="middle" />}
                            {!loading && 'Ingresar'}
                        </Button>
                    </Form.Item>
                    <div className="register-link-login">
                        <Copyright />
                    </div>
                </Form>
            </div>
        </Layout>
    )
}

export default Login;
