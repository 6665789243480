import { useState, useEffect, useMemo } from 'react';
import Styles from '../../styles/Styles';
import { Spin, Button, Radio, Row, Col, Divider } from 'antd';
import ClientsRepo from './ClientsRepo';
import { ToastErr, ToastOk } from '../../shared/Toast';
import Select from "react-select";
import { useTable } from 'react-table';
import { VerticalAlignBottomOutlined, SendOutlined } from '@ant-design/icons';
import './style.css';
import { Typography, Space } from 'antd';

const { Text } = Typography;

const options = [
    { id: 0, value: "0", label: 'Contado' },
    { id: 7, value: "7", label: "7 DÍAS" },
    { id: 15, value: "15", label: "15 DÍAS" },
    { id: 20, value: "20", label: "20 DÍAS" },
    { id: 30, value: "30", label: "30 DÍAS" },
];

function Table({ columns, data }) {
    // Use the state and functions returned from useTable to build your UI
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow
    } = useTable({
        columns,
        data,
    })

    // Render the UI for your table
    return (
        <table {...getTableProps()}>
            <thead>
                {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => (
                            <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                        ))}
                    </tr>
                ))}
            </thead>
            <tbody {...getTableBodyProps()}>
                {rows.map((row, i) => {
                    prepareRow(row)
                    return (
                        <tr {...row.getRowProps()}>
                            {row.cells.map(cell => {
                                return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                            })}
                        </tr>
                    )
                })}
            </tbody>
        </table>
    )
}

export default function Clients() {

    const [data, setData] = useState([]);
    const { getClients, updateCredit, updateDirection } = ClientsRepo();
    const [isLoaded, setIsLoaded] = useState(false);

    const columns = useMemo(
        () => [
            {
                Header: 'RUC',
                accessor: 'identificador',
            },
            {
                Header: 'Razón Social',
                accessor: 'nombre',
            },
            {
                Header: 'Dirección',
                accessor: 'direccion',
            },
            {
                Header: 'Distrito',
                accessor: 'distrito',
            },
            {
                Header: 'Departamento',
                accessor: 'departamento',
            },
            {
                Header: 'Provincia',
                accessor: 'provincia',
            },
            {
                Header: 'Ubigeo',
                accessor: 'ubigeo',
            },
            {
                Header: 'Crédito',
                accessor: 'credito',
                Cell: props => EditableDrop(props, updateMyCredit)
            },
            {
                Header: 'Obtener Dirección',
                Cell: props => UpdateDirButton(props)
            },
        ],
        []
    );

    useEffect((update) => {
        updateData(false);
    }, []);


    const updateData = async (update = false) => {
        setIsLoaded(false);

        await getClients(update).then((response) => {
            setData(response.data);
            console.log(data);
            setIsLoaded(true);

            ToastOk(response.message);
        }).catch((error) => {
            console.error(error);
            setIsLoaded(true);

            ToastErr(error.message);
        })
    }

    const updateMyCredit = async (rowIndex, columnId, value, payload) => {
        // We also turn on the flag to not reset the page
        // setSkipPageReset(true)
        // console.log(rowIndex, columnId, value)
        return updateCredit(payload).then((data) => {
            ToastOk(`Crédito de cliente: ${payload.identificador} actualizado.`)
            setData(old =>
                old.map((row, index) => {
                    if (index === rowIndex) {
                        return {
                            ...old[rowIndex],
                            [columnId]: value,
                        }
                    }
                    return row
                })
            )
        })
    }

    const EditableDrop = ({
        value: initialValue,
        row: { index },
        column: { id },
        cell: { row }, },
        updateMyCredit  // This is a custom function that we supplied to our table instance
    ) => {
        // We need to keep and update the state of the cell normally
        const [value, setValue] = useState(
            row.original.Billing_ov_arm?.credito
        );
        const [loading, setLoading] = useState(false);
        const [changed, setChanged] = useState(false);

        const customStyles = {
            option: (provided, state) => ({
                ...provided,
                borderBottom: '1px dotted pink',
                color: state.isSelected ? 'white' : 'black',
                padding: 20,
            }),
            control: () => ({
                // none of react-select's styles are passed to <Control />
                width: 120,
                color: 'black'
            }),
            singleValue: (provided, state) => {
                const opacity = state.isDisabled ? 0.5 : 1;
                const fontSize = '10px';
                const transition = 'opacity 300ms';
                const color = '#0E0D0D';
                return { ...provided, opacity, transition, color, fontSize };
            }
        }

        // We'll only update the external data when the input is blurred
        const onCredit = () => {
            if (changed) {
                setLoading(true);
                let payload = { identificador: row.original.identificador, credito: value }
                updateMyCredit(index, id, value, payload).then(() => {
                    setLoading(false)
                }).catch((err) => {
                    console.log(err)
                    setValue(row.original.credito ?? null);
                    ToastErr(err.message);
                    setLoading(false)
                })
            } else {
                ToastOk('Sin cambios');
            }
        };
        const focus = () => {
            // console.log('Focus')
            setChanged(false);
        }
        const change = (e) => {
            if (e !== value) {
                setValue(e);
                setChanged(true);
            }
            // console.log('Change ',e)
        }
        // If the initialValue is changed external, sync it up with our state
        useEffect(() => {
            setValue(initialValue);
        }, [initialValue]);

        return (
            <div>
                <Row>
                    <Col xs={18} sm={18} md={18} lg={18} xl={18}>
                        <Select
                            styles={customStyles}
                            // initialValue={value}
                            options={options}
                            //onBlur={onBlur}
                            value={value}
                            onChange={change}
                            onFocus={focus}
                        />
                    </Col>
                    <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                        <Button onClick={onCredit} icon={<SendOutlined />} style={{ "backgroundColor": "#3FC61D", "borderColor": "#3FC61D" }}
                        />
                        {loading ? <Spin size="small" /> : <div></div>}
                    </Col>
                </Row>
            </div>
        );
    };

    const updateDirectionState = async (identificador, nombre, rowIndex) => {
        try {
            let data = await updateDirection(identificador)
            ToastOk(`Datos de cliente: ${nombre} actualizado.`)
            setData(old =>
                old.map((row, index) => {
                    if (index === rowIndex) {
                        return {
                            ...old[rowIndex],
                            ...data.data
                        }
                    }
                    return row
                })
            )
        } catch (error) {
            ToastErr(`Error actualizando datos de ${nombre}`)
        }
    }

    const UpdateDirButton = ({
        value: initialValue,
        row: { index },
        column: { id },
        cell: { row }, } // This is a custom function that we supplied to our table instance
    ) => {
        const [loading, setLoading] = useState(false)
        const updDir = async () => {
            setLoading(true);
            await updateDirectionState(row.original.identificador, row.original.nombre, index)
            setLoading(false);
        }
        return (
            <Row className='btnDir' justify="center" >
                <Button
                    type="primary"
                    shape="circle"
                    icon={<VerticalAlignBottomOutlined />}
                    // style={{ "backgroundColor": "#dc3545", "borderColor": "#dc3545" }}
                    size="large" onClick={updDir} />
                {loading ? <Spin size="small" /> : <div></div>}
            </Row>
        );
    };

    return (
        <Styles>
            {(!isLoaded) ?
                // <Skeleton active /> 
                <div style={{ height: "500px", position: "relative" }}>
                    <div style={{ position: "absolute", top: "50%", left: "50%", color: "black" }}><Spin /> Obteniendo información </div>
                </div> :
                <>
                    <Row justify="space-around">
                        <Col>
                            <Row>
                                <Text strong>Clientes Registrados</Text></Row>
                        </Col>
                        <Col>
                            <Row>
                                <Radio.Group value="large">
                                    <Radio.Button
                                        type="primary" onClick={() => updateData(false)}>
                                        Actualizar Armadillo
                                    </Radio.Button>
                                    <Radio.Button
                                        type="primary" onClick={() => updateData(true)}>
                                        Actualizar TMS
                                    </Radio.Button>
                                </Radio.Group>
                            </Row>
                        </Col>
                    </Row>
                    <Divider />
                    <Table columns={columns} data={data} />
                    <div style={{height:"50px"}}></div>
                </>
            }
        </Styles>
    )

}