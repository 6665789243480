import { Layout } from "antd";
import Logo from "./Logo";
import AccountButton from "./AccountButton";

const { Header } = Layout;

/**
 * Header de la aplicación (vista admin)
 */
const HeaderSection = () => {
  return (
    <Header style={{ background: "#fff" }}>
      <Logo />
      <AccountButton />
    </Header>
  );
};

export default HeaderSection;
