import React from "react";
import { Card, Col, Row, Layout, Typography } from "antd";
const { Content } = Layout;
const { Title, Paragraph } = Typography;

const Main = () => {
  return (
    <Layout className="layout">
      <Content style={{ padding: "0 50px" }}>
        <Row justify="center" align="middle" style={{ height: "80vh" }}>
          <Card>
            <Col>
              <Title level={3}>Bienvenido al portal Armadillo</Title>
              <Paragraph style={{ fontSize: "1.2em" }}>
                Navegue usando el menú lateral 👈
              </Paragraph>
            </Col>
          </Card>
        </Row>
      </Content>
    </Layout>
  );
}

export default Main;
