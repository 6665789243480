import { useState, useEffect, useRef } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { Button, Input, Space, Table } from 'antd';
import moment from 'moment';
import Highlighter from 'react-highlight-words';
import NestedOPTTable from './NestedOPTTable';

import controlRepo from '../helpers/controlRepo';

const PrincipalOPTTable = ({ setReload, reloadButton, loading, setLoading, dataTable, setDataTable }) => {

  const { getCheckPoints } = controlRepo();

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);

  useEffect(() => {
    fetchData();
  }, [reloadButton]);

  const fetchData = () => {
    setLoading(true)
    getCheckPoints()
      .then(({data}) => {
        setDataTable(data);
        setLoading(false)
      })
  }

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset 
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      width: 140,
      title: 'OPT',
      dataIndex: 'opt',
      sorter: (a, b) => a.opt - b.opt,
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('opt'),
    },
    {
      title: 'Conductor',
      dataIndex: 'conductor',
      ...getColumnSearchProps('conductor')
    },
    {
      title: 'Vehículo',
      dataIndex: 'placa',
      ...getColumnSearchProps('placa')
    },
    {
      title: 'Fecha Creación de OPT',
      dataIndex: 'fecha',
      render:(fecha) => moment(fecha, "DD/MM/YYYY HH:mm:ss").format('L'),
      sorter: (a, b) => moment(a.fecha, "DD/MM/YYYY HH:mm:ss").unix() - moment(b.fecha, "DD/MM/YYYY HH:mm:ss").unix(),
      sortDirections: ['descend', 'ascend', 'descend'],
      defaultSortOrder: 'descend',
    },
    {
      title: 'Inicio de Servicio',
      children: [
        {
          title: 'Fecha de Inicio',
          dataIndex: 'fechaInicio'
        }
      ]
    },
  ];

  return (
    <>
      <Table
        columns={columns}
        expandable={{
          expandedRowRender: (record) =>
            <NestedOPTTable
              opt={record.opt}
              optguid={record.optguid}
              ort={record.numeroORT}
              ortguid={record.ortguid}
              content={record.eventos}
              reloadButton={reloadButton}
              setReload={setReload}
            />,
        }}
        dataSource={dataTable}
        size={'middle'}
        loading={loading}
        bordered={true}
        scroll={{
          x: 'calc(750px + 50%)',
          y: 500,
        }}
      />
    </>
  );
};
export default PrincipalOPTTable;